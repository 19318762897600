import React from "react";
import "../styles/contacts.scss";

function Contacts() {
  return (
    <div className="contacts block" id="contact">
      <div className="heading">КОНТАКТЫ</div>
      <div className="content">
        <div className="left">
          <div className="textme">
            <div className="title">Напишите мне</div>
            <p>
              Положитесь на опытного адвоката в Казани. Связаться со мной можно
              по телефону.
            </p>
          </div>
          <div className="name">ШАКИРОВ ЛЕНАР НАИЛЕВИЧ</div>
        </div>
        <div className="right">
          <div className="c-list">
            <a className="c-elem" href="https://yandex.ru/maps/-/CDu~jSPS">
              420111, Г.КАЗАНЬ, УЛ. МИСЛАВСКОГО, Д. 9, ОФИС 422
              {/* 420111, г. Казань, ул. Миславского, д. 9, офис 422 */}
            </a>
            {/*<a className="c-elem" href="tel: +7 (843) 236-92-70">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.4895 2.5H7.4895C6.38493 2.5 5.4895 3.39543 5.4895 4.5V20.5C5.4895 21.6046 6.38493 22.5 7.4895 22.5H17.4895C18.5941 22.5 19.4895 21.6046 19.4895 20.5V4.5C19.4895 3.39543 18.5941 2.5 17.4895 2.5Z"

                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.4895 18.5H12.4995"

                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              +7 (843) 236-92-70
            </a>*/}
            <a className="c-elem" href="tel:+7(917)907-55-22">
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.4896 16.4201V19.4201C21.4907 19.6986 21.4336 19.9743 21.3221 20.2294C21.2105 20.4846 21.0469 20.7137 20.8416 20.902C20.6364 21.0902 20.3941 21.2336 20.1303 21.3228C19.8665 21.412 19.5869 21.4452 19.3096 21.4201C16.2324 21.0857 13.2766 20.0342 10.6796 18.3501C8.26339 16.8148 6.21489 14.7663 4.67956 12.3501C2.98954 9.7413 1.9378 6.77109 1.60956 3.6801C1.58457 3.40356 1.61743 3.12486 1.70606 2.86172C1.79468 2.59859 1.93713 2.35679 2.12432 2.15172C2.31152 1.94665 2.53936 1.78281 2.79335 1.67062C3.04733 1.55843 3.3219 1.50036 3.59956 1.5001H6.59956C7.08486 1.49532 7.55535 1.66718 7.92332 1.98363C8.29129 2.30008 8.53163 2.73954 8.59956 3.2201C8.72618 4.18016 8.96101 5.12282 9.29956 6.0301C9.4341 6.38802 9.46322 6.77701 9.38346 7.15098C9.30371 7.52494 9.11842 7.86821 8.84956 8.1401L7.57956 9.4101C9.00311 11.9136 11.076 13.9865 13.5796 15.4101L14.8496 14.1401C15.1214 13.8712 15.4647 13.6859 15.8387 13.6062C16.2126 13.5264 16.6016 13.5556 16.9596 13.6901C17.8668 14.0286 18.8095 14.2635 19.7696 14.3901C20.2553 14.4586 20.699 14.7033 21.0161 15.0776C21.3332 15.4519 21.5017 15.9297 21.4896 16.4201Z"
                  stroke=""
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              +7 (917) 907-55-22
            </a>
            <a className="c-elem" href="mailto:lenarlawyer@yandex.ru">LENARLAWYER@YANDEX.RU</a>
            {/* <a className="c-elem" href="mailto:lenarlawyer@yandex.ru">lenarlawyer@yandex.ru</a> */}
          </div>
          <div className="sm">
            <div> 
              <a href='https://wa.me/79179075522' class='sm-elem-a'><div className="sm-elem">WhatsApp<svg width="45" height="30" viewBox="0 0 45 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.65613 15H35.3228" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M22.4895 6.25L35.3228 15L22.4895 23.75" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </div></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
