import React, { useState } from "react";
import "../styles/chosenview.scss"


function ChooseView() {

    const [chosen, choose] = useState(0)

    
    let changingView = [
    <div class='adv-sud-block'>
    <div class='header-2'>
      <h1 class='header-2-h1'>Адвокат в суде - надежная, квалифицированная поддержка специалиста.</h1>
    </div>
    <div class='adv-sud-body'>
      <p class='adv-sud-txt'>Обращение к адвокату в суде - это гарантия соблюдения всех 
      норм и правил, а также законодательства. В условиях возбуждения уголовного 
      дела или привлечения к уголовной ответственности важно немедленно обратиться к опытному 
      адвокату, который обладает:</p>
      <div class='adv-sud-skills'>
        <div class='adv-sud-skill-1'>
          <div class='adv-sud-skill-head'>
            <div class='adv-sud-skill-circle'><p class='adv-sud-skill-circle-p'>1</p></div>
            <h2 class='adv-sud-skill-1-h2'>Знанием закона</h2>
          </div>
          <p class='adv-skills-txt'>Это позволяет адвокату эффективно представлять интересы своих клиентов, строить аргументацию, составлять 
            документы и успешно выступать перед судом или другими юридическими органами</p>
        </div>
        <div class='adv-sud-skill-2'>
          <div class='adv-sud-skill-head'>
            <div class='adv-sud-skill-circle'><p class='adv-sud-skill-circle-p'>2</p></div>
            <h2 class='adv-sud-skill-2-h2'>Практическим опытом</h2>
          </div>
          <p class='adv-skills-txt'>Опыт работы со схожими делами позволяет адвокату лучше понимать особенности 
          и тонкости соответствующего правового вопроса, разрабатывать эффективные стратегии и тактики, а также 
          применять успешные решения и прецеденты в своей работе.</p>
        </div>
        <div class='adv-sud-skill-3'>
          <div class='adv-sud-skill-head'>
            <div class='adv-sud-skill-circle'><p class='adv-sud-skill-circle-p'>3</p></div>
            <h2 class='adv-sud-skill-3-h2'>Готовностью и желанием помочь</h2>
          </div>
          <p class='adv-skills-txt'>Это позволяет адвокату лучше понять и удовлетворить потребности клиента, повысить 
          доверие и установить продуктивные рабочие отношения. Так же желание помочь увеличивает эффективность работы.</p>
        </div>
        <div class='adv-sud-skill-4'>
          <div class='adv-sud-skill-head'>
            <div class='adv-sud-skill-circle'><p class='adv-sud-skill-circle-p'>4</p></div>
            <h2 class='adv-sud-skill-4-h2'>Умением представлять интересы клиента</h2>
          </div>
          <p class='adv-skills-txt'>Умение адвоката представлять интересы клиента играет важную роль в защите 
          прав и обеспечении справедливости в судебных процедурах.</p>
        </div>
      </div>
      <p class='adv-sud-txt'>Судебный процесс считается одним из самых сложных и трудоемких направлений 
      в юридической практике. Окончательное разрешение любого конфликта между сторонами происходит 
      в стенах суда. Поэтому важно обратиться к профессионалу, способному достойно представить интересы своего 
      клиента и предоставить убедительные доказательства в его пользу.</p>
      <h2 class='adv-sud-h2'>Задачи, которые стоят перед адвокатом в суде</h2>
      <p class='adv-sud-txt'>Деятельность адвоката в суде достаточно обширна. 
      Перед специалистом стоят следующие задачи: 
      1. Четко сформировать тактику защиты, учитывая все обстоятельства дела.  
      2. Консультировать клиента в ходе ведения процесса. 
      3. Находить документальные подтверждения, а также свидетелей по делу, 
      которые смогут подтвердить невиновность подзащитного. 
      4. Составлять все необходимые в ходе процесса адвокатские запросы.</p>
      <p class='adv-sud-txt'>Адвокат в суде ограждает своего подзащитного от неправомерных действий 
      уполномоченных лиц органов правопорядка. 
      Возможно даже обжалование действий судьи, следователя и прокурора.</p>
      <p class='adv-sud-txt'>Для успешного разрешения судебных вопросов, необходимо 
      обратиться к опытному адвокату. В Казани предоставляется доступ к высококвалифицированным 
      юристам, которые готовы проконсультировать в любое удобное для Вас время. Сотрудничая с 
      профессионалами, Вы повышаете свои шансы на положительное решение юридических вопросов.</p>
    </div>
    </div>, 
    <div className="adv-ug-del-block">
      <div class='header-2'>
        <h1 class='header-2-h1'>Адвокат по уголовным делам в Казани.</h1>
      </div>
      <div class='adv-ug-del-body'>
        <p class='adv-ug-del-txt'>Адвокат по уголовным делам обладает способностью предоставить качественную 
        правовую помощь своим клиентам, основываясь на глубоком знании законов. Область деятельности 
        такого специалиста очень широка, поскольку адвокат имеет законное право вступать в 
        дело на любой его стадии.</p>
        <p class='adv-ug-del-txt'>Поэтому доверители могут рассчитывать на защиту своих интересов:</p>
        <li class='adv-ug-del-li'>на стадии ведения досудебного расследования;</li>
        <li class='adv-ug-del-li'>в суде первой инстанции;</li>
        <li class='adv-ug-del-li'>в суде апелляционной инстанции;</li>
        <li class='adv-ug-del-li'>в суде кассационной инстанции.</li>
        <p class='adv-ug-del-txt'>Адвокат может представлять интересы клиента в случае досрочного погашения судимости. 
        На сегодняшний день адвокат по уголовным делам имеет право работать 
        не только с подозреваемыми и подсудимыми, но и оказывать услуги и проводить консультации с потерпевшими
        и свидетелями</p>
        <h2 className='adv-ug-del-h2'>Спектр услуг квалифицированного адвоката по уголовным делам.</h2>
        <p class='adv-ug-del-txt'>Опытные специалисты готовы предоставить профессиональную поддержку 
        своим клиентам, провести консультацию по их запросам и оценить перспективы исхода уголовного дела. 
        Услуги адвоката помогут защитить Ваши права. Еще одной полезной услугой, предоставляемой нашими 
        адвокатами, является разработка документации, которая включает в себя подготовку исковых заявлений.</p>
        <p class='adv-ug-del-txt'>Адвокат по уголовным делам обладает полномочиями для беспрепятственного 
        консультирования клиентов, без временных ограничений, имеет возможность 
        проводить конфиденциальные беседы с доверителями, исключая присутствие представителей органов 
        правопорядка, а также имеет право участвовать во всех следственных действиях, собирать необходимые 
        доказательства и находить свидетелей для дела.</p>
        <p class='adv-ug-del-txt'>Широкий спектр полномочий становится гарантией достижения поставленных задач и 
        разрешения судебных разбирательств в пользу клиента.</p>
      </div></div>,
      <div class='adv-ug-block'>
        <div class='header-2'>
          <h1 class='header-2-h1'>Адвокат по защите бизнеса.</h1>
        </div>
        <div class='adv-ug-body'>
          <p class='adv-ug-del-txt'>Наши адвокаты специализируются на защите интересов бизнеса и помогут 
          Вам эффективно решить любые правовые вопросы, с которыми сталкиваются предприниматели. Мы осознаем, 
          что ведение успешной деятельности связано с множеством рисков и сложностей, поэтому предлагаем 
          широкий спектр юридических услуг, направленных на обеспечение стабильности и защиты вашего бизнеса.</p>
          <p class='adv-ug-del-txt'>Наши адвокаты могут помочь при разрешении различных споров, 
          арбитражных претензий, проведении налоговых проверок или в защите по уголовным экономическим 
          делам любой сложности. Мы возьмем на себя всю работу по подготовке документов, составлению и 
          подаче исковых заявлений, обеспечим представительство в суде и предоставим всестороннюю 
          правовую поддержку.</p>
          <p class='adv-ug-del-txt'>Кроме того, наши адвокаты помогут Вам защитить 
          Вашу интеллектуальную собственность и права на товарные знаки, авторские 
          права и патенты. Мы окажем юридическую поддержку при оформлении контрактов и 
          сделок, поможем спланировать оптимальную стратегию развития Вашего бизнеса и 
          проконтролировать соблюдение налогового и корпоративного законодательства.</p>
          <p class='adv-ug-del-txt'>Наши адвокаты обладают обширными знаниями и опытом в области бизнес-права, 
          что позволяет нам эффективно защищать Ваш бизнес и предотвращать возможные проблемы. Мы готовы выслушать 
          Ваши вопросы, дать профессиональную оценку ситуации и разработать индивидуальное решение, 
          ориентированное на Ваши интересы и потребности.</p>
        </div>
      </div>, 
      <div class='adv-usl-block'>
        <div class='header-2'>
          <h1 class='header-2-h1'>Услуги профессионального адвоката.</h1>
        </div>
        <div class='adv-usl-body'>
          <p class='adv-ug-del-txt'>Качественное решение и урегулирование юридических споров требуют услуг адвоката. 
          Наша команда квалифицированных и надежных специалистов обеспечивает 
          добросовестный и ответственный подход к каждому клиенту. Мы придерживаемся принципов конфиденциальности и 
          несем ответственность перед нашими доверителями. Эти принципы являются основой успешной адвокатской 
          деятельности.</p>
          <p class='adv-ug-del-txt'>В качестве регулятора любых общественных отношений выступает право. В современном мире, к 
          сожалению человек не может отслеживать изменения в законодательстве. Из-за этого возникают сложные, не всегда законные
          ситуации.</p>
          <p class='adv-ug-del-txt'>Адвокаты являются востребованными так как, они 
          предоставляют возможность решить проблему, исключая серьезные рисков. Своевременное обращение 
          за консультацией и помощью помогает сильно сократить материальные и моральные потери.</p>
          <h2 class='adv-ug-del-h2'>Услуги от профессиональных адвокатов</h2>
          <p class='adv-ug-del-txt'>Штат квалифицированных специалистов, в основе деятельности которых лежат разные 
          направления права, готов:</p>
          <li class='adv-ug-del-li'>оказать устные или письменные консультации, которые помогут определить 
          сложность проблемы;</li>
          <li class='adv-ug-del-li'>определить перспективы благоприятного для клиента решения спора;</li>
          <li class='adv-ug-del-li'>подготовить юридические документы с возможностью последующей 
          подачи их в соответствующие органы;</li>
          <li class='adv-ug-del-li'>представлять интересов доверителя в органах государственной власти, 
          местного самоуправления, учреждениях или предприятиях;</li>
          <li class='adv-ug-del-li'>представлять интересов в судах общей юрисдикции, в арбитражных 
          судах, составление исков, отзывов и возражений, подготовка правового анализа юридического вопроса.</li>
          <li class='adv-ug-del-li'>помочь с регистрацией предприятий, общественных организаций, 
          благотворительных фондов;</li>
          <li class='adv-ug-del-li'>обеспечение исполнения судебных постановлений;</li>
          <li class='adv-ug-del-li'>организовать досудебное урегулирование возникших споров;</li>
          <li class='adv-ug-del-li'>обеспечить адвокатскую защита подозреваемых и обвиняемых по уголовным делам.</li>
          <p class='adv-ug-del-txt'>Наша главная цель - обеспечить надежность и доступность услуг. Когда Вы 
          обратитесь к нашему адвокату, обладающему большим опытом и практикой в решении различных 
          юридических споров, мы обязательно разрабатываем стратегию для вашего дела. Этот процесс является 
          обязательным для нас, чтобы гарантировать эффективность работы.</p>
          <p class='adv-ug-del-txt'>Обращение к опытному профессионалу для получения консультации - это гарантия вашего спокойствия и спокойствия Ваших близких.</p>
          <h2 class='adv-ug-del-h2'>Полный перечень предоставляемых услуг:</h2>
          
        </div>
      </div>  
    ][chosen]

    return [<div class='sec-header-2'  id="service">
    <h1 class='sec-header-h'>Услуги профессионального юриста</h1>
  </div>,
  <div class='sec-navbar'>
    <div className="nav-wrap">
    <div class={`${chosen === 0 ? "chosen" : ""} cbr adv-sud`} onClick={()=>{choose(0)}}>
      <p class='adv-sud-p'>Адвокат в суде</p>
    </div>
    <div class={`${chosen === 1 ? "chosen" : ""} cbr adv-ug-del`}  onClick={()=>{choose(1)}}>
      <p class='adv-ug-del-p'>Адвокат по уголовным делам</p>
    </div>
    <div class={`${chosen === 2 ? "chosen" : ""} cbr adv-ug `}  onClick={()=>{choose(2)}}>
      <p class='adv-ug-p'>Защита бизнеса</p>
    </div>
    <div class={`${chosen === 3 ? "chosen" : ""} cbr adv-usl`}  onClick={()=>{choose(3)}}>
      <p class='adv-usl-p'>Услуги адвоката</p>
    </div></div>
  </div>, changingView
  ]
    
}

export default ChooseView;