import '../styles/Main.scss';
import React, { useEffect, useState } from "react";
import img1 from "../images/img-1.jpg";
import img2 from "../images/img-2.png"
import Contacts from '../components/Contacts';
import ChooseView from '../components/ChooseView';
import Footer from '../components/Footer';
import Menu from '../components/Menu';

function Main() {


  const [isOpen, open] = useState(false)

  return (
    <body>
      <div className="APP">
        <div class='main'>
          <Menu isOpen={isOpen} open={open}/>
          <div class='header block'>
            <h1 class='header-h1'>Адвокат Шакиров Ленар Наилевич</h1>
            <div class='right'>
              <div class='navbar'>
                {/* <p class='navbar-about' href='#about'>ОБО МНЕ</p>
                <p class='navbar-services' href='#services'>УСЛУГИ</p>
                <p class='navbar-contacts' href='#contacts'>КОНТАКТЫ</p> */}
                <svg class='menu-svg' onClick={()=>{open(!isOpen)}} width="30px" height="30px" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 6H20M4 12H20M4 18H20" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
              </div>
            </div>
          </div>
          <div class='wrap'></div>
          <div class='first block' style={{"padding-bottom": "0"}}>
            <div class='first-block'>
            <div class='first-block-left'>
              <h1 class='first-block-h1-1'>МЫ ВЫИГРЫВАЕМ</h1>
              <h1 class='first-block-h1-2'>БУМАЖНЫЕ ВОЙНЫ</h1>
            </div>
            <div class='first-block-right'>
              <a href="#contact" class='first-block-consult'>
                <p class='first-block-consult-p'>ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ</p>
              </a>
              <p class='first-block-consult-pp'>
                ОБРАЩАЯСЬ ЗА АДВОКАТСКИМИ УСЛУГАМИ КО МНЕ, МОЖНО РАССЧИТЫВАТЬ НА ТО, 
                ЧТО НАД ВОПРОСОМ БУДУТ РАБОТАТЬ СПЕЦИАЛИСТЫ ВЫСОКОЙ КВАЛИФИКАЦИИ.
              </p>
            </div>
            </div>
            <div class='first-block-img'>
              <div class='img-1-a'><img class='img-1' src={img1}/></div>
            </div>
          </div>
          <div class='second-block block' id="me">
            <div class='sec-block-h'>
              <h1 class='second-block-head'>ОБО МНЕ</h1>  
            </div>
            <div class='sec-block'>
              <div class='sec-left'>
                <h2 class='second-block-name'>Шакиров Ленар Наилевич</h2>
                <a class='sec-left-img-a' href="#"><img class='sec-left-img' src={img2}/></a>
              </div>
              <div class='sec-right'>
                <div class='sec-right-1'>
                  <div>
                    <h3 class='sec-right-h3'>Огромный опыт</h3>
                    <p class='sec-right-p'>Юридический стаж с 2009 года. Являюсь членом адвокатской палаты Республики Татарстан.
                    Имею опыт работы в следственных органах.
                    Успешно представляю и защищаю интересы своих клиентов</p>
                  </div>
                </div>
                <div class='sec-right-2'>
                  <div>
                    <h3 class='sec-right-h3'>Высокая<br />квалификация</h3>
                    <p class='sec-right-p'>Обращаясь к нам, можете быть уверены, что над Вашим вопросом будут работать только специалисты с выскоким уровнем квалификации.
                    Наши эксперты являются специалистами во всех направлениях права</p>
                  </div>
                </div>
                <div class='sec-right-3'>
                  <div>
                    <h3 class='sec-right-h3'>Гарантия</h3>
                    <p class='sec-right-p'>Мы гарантируем квалифицированную юридическую помощь, полную конфиденциальность и сохранение адвокатской тайны.
                    Вы сможете чувствовать себя уверенно и защищенно под опекой адвокатов города Казань</p>
                  </div>
                </div>
              </div>
            </div>
            <ChooseView/>
          </div>
          <Contacts />
          <Footer/>
        </div>
      </div>
    </body>
  );
}

export default Main;
