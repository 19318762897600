import React, {useState} from "react";
import "../styles/menu.scss"

function Menu({isOpen, open}) {
    console.log(isOpen)
    return <div className={`${isOpen ? 'open': ''} menu`}>
        <div className="close" onClick={()=>{open(!isOpen)}} >Закрыть</div>
        <h1 class='header-h1'>Шакиров Ленар</h1>
        <div className="links">
        <a href="#me"  className="link" onClick={()=>{open(!isOpen)}}>ОБО МНЕ</a>
        <a href="#service" className="link" onClick={()=>{open(!isOpen)}}>УСЛУГИ</a>
        <a href="#contact"  className="link" onClick={()=>{open(!isOpen)}}>КОНТАКТЫ</a></div>
        <div className="header-h1"></div>
        <div className="header-h1"></div>
    </div>
}

export default Menu;